import isBrowser from '@utils/isBrowser'

export const gtagEnabled = () => isBrowser && typeof window.gtag === 'function'

// @ts-ignore
export const klaviyoEnabled = () => !!process.env.GATSBY_KLAVIYO_ID

// @ts-ignore
export const fbqEnabled = () => isBrowser && typeof fbq === 'function'

// @ts-ignore
export const redditEnabled = () => isBrowser && typeof rdt === 'function'

// @ts-ignore
export const triplePixelEnabled = () => isBrowser && TriplePixel.__cc

// @ts-ignore
export const quantCastEnabled = () => isBrowser && typeof _qevents === 'function'
