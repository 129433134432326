import {
    fbqEnabled,
    gtagEnabled,
    klaviyoEnabled,
    quantCastEnabled,
    redditEnabled,
    triplePixelEnabled,
} from '@utils/tracking/index'
import { Cart, CartLineInput } from '@graphql/shopify/generated'

export const trackAddToCart = (cart: Cart, lineItemsToUpdate: CartLineInput[]) => {
    const addedItem = cart.lines.edges.find(item => item.node.merchandise.id === lineItemsToUpdate[0].merchandiseId)

    if (!addedItem) {
        return
    }

    if (triplePixelEnabled()) {
        TriplePixel('AddToCart', { item: addedItem.node.merchandise.sku, q: addedItem.node.quantity })
    }

    if (redditEnabled()) {
        rdt('track', 'AddToCart')
    }

    if (gtagEnabled()) {
        gtag('event', 'add_to_cart', {
            currency: cart.estimatedCost.totalAmount.currencyCode,
            value: cart.estimatedCost.totalAmount.amount,
            items: [
                {
                    item_id: addedItem.node.merchandise.sku,
                    item_name:
                        addedItem.node.merchandise.title !== 'Default Title'
                            ? addedItem.node.merchandise.title
                            : addedItem.node.merchandise.product.title,
                    price: addedItem.node.estimatedCost.totalAmount.amount,
                    quantity: addedItem.node.quantity,
                },
            ],
        })
    }

    if (klaviyoEnabled() && _learnq) {
        _learnq?.push([
            'track',
            'Added to Cart',
            {
                $value: cart.estimatedCost.totalAmount.amount,
                AddedItemProductName:
                    addedItem.node.merchandise.title !== 'Default Title'
                        ? addedItem.node.merchandise.title
                        : addedItem.node.merchandise.product.title,
                AddedItemSKU: addedItem.node.merchandise.sku,
                AddedItemPrice: addedItem.node.estimatedCost.totalAmount.amount,
                AddedItemQuantity: addedItem.node.quantity,
                ItemNames: cart.lines.edges.map(item =>
                    item.node.merchandise.title !== 'Default Title'
                        ? item.node.merchandise.title
                        : item.node.merchandise.product.title,
                ),
                // "CheckoutURL": cart.webUrl,
                Items: cart.lines.edges.map(item => ({
                    SKU: item.node.merchandise.sku,
                    ProductName:
                        item.node.merchandise.title !== 'Default Title'
                            ? item.node.merchandise.title
                            : item.node.merchandise.product.title,
                    Quantity: item.node.quantity,
                    ItemPrice: item.node.merchandise.price.amount,
                    RowTotal: item.node.estimatedCost.totalAmount,
                })),
            },
        ])
    }

    if (fbqEnabled()) {
        fbq('track', 'AddToCart', {
            content_name:
                addedItem.node.merchandise.title !== 'Default Title'
                    ? addedItem.node.merchandise.title
                    : addedItem.node.merchandise.product.title,
            content_ids: [addedItem.node.merchandise.sku],
            content_type: 'product',
            value: cart.estimatedCost.totalAmount.amount,
            currency: cart.estimatedCost.totalAmount.currencyCode,
        })
    }

    if (quantCastEnabled()) {
        //@ts-ignore
        window._qevents = window._qevents || []
        //@ts-ignore
        window._qevents.push({
            qacct: 'p-qPJ8pgFAAFm12',
            labels: '_fp.event.AddToCart',
            orderid: cart?.id,
            revenue: cart.estimatedCost.totalAmount.amount,
            event: 'refresh',
        })
    }
}
